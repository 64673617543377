/* woff2 : Super Modern Browsers */
/* woff :Modern Browsers */

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/anek-malayalam-v4-malayalam-100.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam-v4-malayalam-100.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/anek-malayalam-v4-malayalam-200.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam-v4-malayalam-200.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/anek-malayalam-v4-malayalam-300.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam-v4-malayalam-300.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/anek-malayalam-v4-malayalam-400.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam-v4-malayalam-400.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/anek-malayalam-v4-malayalam-500.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam-v4-malayalam-500.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/anek-malayalam-v4-malayalam-600.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam-v4-malayalam-600.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/anek-malayalam-v4-malayalam-700.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam-v4-malayalam-700.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Anek Malayalam';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/anek-malayalam-v4-malayalam-800.woff2')
      format('woff2'),
    url('../assets/fonts/anek-malayalam-v4-malayalam-800.woff') format('woff');
  font-display: swap;
}
